import { css } from 'styled-components';

export default css`
  .chakra-modal__header {
    background-color: transparent;
    padding: 1rem 1rem 0rem 1rem !important;
    margin-bottom: 0.5rem;

    h2 {
      margin: 0;
      color: ${({ theme }) => theme.colors.blue};
      text-align: center;
    }

    span {
      font-size: inherit;
    }
  }

  .chakra-modal__close-btn {
    background: transparent;
    border-width: 0;

    position: relative;
    margin-left: auto;

    &:hover {
      background: ${({ theme }) => theme.colors.medium.color} !important;
    }

    svg {
      color: ${({ theme }) => theme.colors.title};
    }
  }

  .chakra-modal__content {
    border-radius: 10px !important;
    padding: 0 2rem;
  }

  .chakra-slide {
    background: ${({ theme }) => theme.colors.white} !important;
  }

  .chakra-modal__body {
    padding: 0 1rem 1rem 1rem !important;
  }
`;
