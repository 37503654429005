import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { SessionProvider } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import GlobalStyle from 'styles/global';
import theme from 'styles/themes/default';

import * as chakrauiTheme from 'config/chackraui-color-theme';
import SEO from 'config/seo';
import 'lib/date-fns';

import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const AppProvider = dynamic(() => import('hooks'), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Router.events.on('routeChangeStart', () => {
  const { body } = document;
  body.classList.add('loading-page');
});
Router.events.on('routeChangeComplete', () => {
  const { body } = document;
  body.classList.remove('loading-page');
});
Router.events.on('routeChangeError', () => {
  const { body } = document;
  body.classList.remove('loading-page');
});

const MyApp: React.FC<AppProps> = ({ Component, pageProps: { session, ...pageProps } }) => (
  <>
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />

      <meta id="HandheldFriendly" content="True" />
      <meta id="MobileOptimized" content="320" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />

      <link rel="shortcut icon" type="image/png" href="/images/logo/favicon.png" />
    </Head>

    <DefaultSeo {...SEO()} />

    <ChakraProvider resetCSS={false} theme={chakrauiTheme.theme}>
      <SessionProvider session={session}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <Component {...pageProps} />

            <ToastContainer />
          </AppProvider>
        </QueryClientProvider>
      </SessionProvider>
    </ChakraProvider>

    <GlobalStyle theme={theme} />

    <ColorModeScript initialColorMode={chakrauiTheme.config.initialColorMode} />

    {process.env.NEXT_PUBLIC_ENABLE_GA === 'true' && (
      <>
        <Script
          async
          type="text/javascript"
          src="https://www.googletagmanager.com/gtag/js?id=PENDENTE"
        />
        <Script type="text/javascript" src="/scripts/ga.js" />
      </>
    )}
  </>
);

export default MyApp;
