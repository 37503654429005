import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  font: {
    title: `'Josefin Sans', serif`,
    paragraph: `'Josefin Sans', sans-serif`,
  },
  colors: {
    blue: '#1f5780',
    blueLight: '#3392D7',
    gray: '#A5A5A5',
    grayDark: '#3C3C3C',
    grayMedium: '#666666',
    grayMediumLight: '#CECECE',
    white: '#FFFFFF',
    orange: '#F19100',
    green: '#29B191',

    // Texts
    title: '#5A544B',
    paragraph: '#5A544B',

    border: '#CECECE',

    // Alerts
    confirmation: {
      color: '#29A97E',
      contrast: '#FFFFFF',
    },
    danger: {
      color: '#E91E54',
      contrast: '#FFFFFF',
    },
    information: {
      color: '#007bff',
      contrast: '#FFFFFF',
    },
    warning: {
      color: '#ffc107',
      contrast: '#FFFFFF',
    },

    // Backgrounds
    light: {
      color: '#F8F6F3',
      contrast: '#8F8F8F',
    },
    medium: {
      color: '#E0DEDA',
      contrast: '#5A544B',
    },
    dark: {
      color: '#8C877F',
      contrast: '#FFFFFF',
    },
    extraDark: {
      color: '#5A544B',
      contrast: '#FFFFFF',
    },
  },
};

export default theme;
